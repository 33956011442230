import store from "../../state/store";
import axios from "axios";

import Vue from "vue";

/**
 * Render a vue template
 *
 * @param {int} elementID
 * @param {string} template
 * @memberof Canvas
 */
async function renderVueTemplate(elementID, template) {
  let vueTemplate;

  vueTemplate = () => import(`../../views/content/${template}.vue`);

  Vue.prototype.$axios = axios.create({
    headers: {
      Authorization: `Bearer ${store.getters.accessToken}`,
    },
    validateStatus: function (status) {
      return status; // Resolve only if the status code is less than 500
    },
  });

  Vue.directive("tooltip", function (el, binding) {
    $(el).tooltip({
      title: binding.value,
      placement: binding.arg,
      trigger: "hover",
    });
  });

  new Vue({
    el: "#" + elementID,
    store,
    render: (h) => h(vueTemplate),
  });
}

export {renderVueTemplate};
